<template>
  <div>
    <Echart :options="options" id="dataEcharts" :height="fontSize(height) + 'px'" :width="fontSize(1210) + 'px'"
      style="margin-top: .104167rem /* 20/192 */;"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    project: {
      type: String,
    },
    data: {
      type: Object,
      default: () => { }
    },
    isPerson: {
      type: Boolean,
    },
    height: {
      type: Number,
      default: 555,
    }
  },
  watch: {
    project: {
      handler(newData) {
        this.setData();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.setData();
    }, 300);
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    isWeekend(date = new Date()) {
      return date.getDay() === 6 || date.getDay() === 0;
    },
    setData() {
      let obj = {}
      obj = this.data
      let SpentTimeByMonth = obj.spentTimeByDays || {}
      let xData = []
      let dailyWork = []
      let overtimeWork = []
      let allTime = []
      Object.keys(SpentTimeByMonth).forEach((key, index) => {
        if (Object.keys(SpentTimeByMonth).length > 30) {
          if ( Object.keys(SpentTimeByMonth).length - index >30) {
            return
          }
        }
        allTime.push((SpentTimeByMonth[key].SpentTime/3600).toFixed(1))
        let a = SpentTimeByMonth[key].Date
        let date = a.substring(0, 4) + "-" + a.substring(4, 6) + "-" + a.substring(6)
        xData.push(key)
        if (SpentTimeByMonth[key].WorkState == 1) {
          dailyWork.push((SpentTimeByMonth[key].SpentTime/3600).toFixed(1))
          overtimeWork.push(0)
        } else if(SpentTimeByMonth[key].WorkState == 2) {
          if (this.isWeekend(new Date(date))) {
            dailyWork.push(0)
            overtimeWork.push((SpentTimeByMonth[key].SpentTime/3600).toFixed(1))
          } else {
            dailyWork.push((27000/3600).toFixed(1))
            overtimeWork.push(((SpentTimeByMonth[key].SpentTime - 27000)/3600).toFixed(1))
          }
        } else {
          dailyWork.push(
            {
              value: (SpentTimeByMonth[key].SpentTime/3600).toFixed(1),
              itemStyle: {
                color: '#ff1395'
              }
            }
          )
          overtimeWork.push(0)
        }
      })
      this.options = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: this.fontSize(16) // 字体大小
          },
          extraCssText: 'width:' + this.fontSize(160) + 'px;height:' + this.fontSize(95) + 'px;',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['正常工时', '加班工时', "工时总计"],
          textStyle: {
            fontSize: this.fontSize(16),
          }
        },
        grid: {
          left: this.fontSize(50) + 'px',
          right: this.fontSize(40) + 'px',
          bottom: this.fontSize(13) + 'px',
          top: this.fontSize(50) + 'px',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              interval: 0,
              rotate: 40,
              color: "#A8D6FF",
              textStyle: {
                fontSize: this.fontSize(14),
              },
              margin: this.fontSize(12),
            },
            axisLine: {
              lineStyle: {
                color: "#A8D6FF",
              },
            },
            axisPointer: {
              type: 'shadow'
            },
            data: xData,
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            axisLabel: {
              color: "#A8D6FF",
              textStyle: {
                fontSize: this.fontSize(14),
              },
              margin: this.fontSize(15),
            },
            splitLine: {
              // 网格线
              show: true,
              lineStyle: { //分割线
                color: "#8A9FB0",
                width: 1,
                type: "dashed" //dotted：虚线 solid:实线
              }
            },
            axisLine: {
              show: false,
            },
          }
        ],
        series: [
          {
            name: '正常工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              normal: {
                color: "#13ffc4",
                lineStyle: {
                  color: "#13ffc4"
                }
              },
            },
            data: dailyWork
          },
          {
            name: '加班工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: overtimeWork
          },
          {
            name: '工时总计',
            type: 'line',
            itemStyle: {
              normal: {
                color: "#cf8011",
                lineStyle: {
                  color: "#cf8011"
                }
              },
            },
            data: allTime
          },
        ]
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>