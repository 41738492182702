<template>
  <div @click="show = false">
    <div class="project-name" @click.stop="show = !show" @mouseover="clearTimer" @mouseleave="setTimer">
      <div style="display: flex; align-items: center;">
        <img src="../assets/images/project.png" alt="" class="project-img">
        <span>{{ projectName }}</span>
      </div>
      <img src="../assets/images/select.png" alt="" class="select-img">
    </div>
    <div class="select-box" v-show="show">
      <div class="select-item" v-for="(item, index) in memberList" :key="item.name" @click.stop="selectProject(item, index)" :style="'font-size:' + fontSize(16) +'px'">
        <span style="margin-left: .15625rem /* 30/192 */;">{{ item.name }}</span>
      </div>
    </div>
    <div class="center" @mouseover="clearTimer" @mouseleave="setTimer">
      <div style="display: flex;">
        <div class="project-box">
          <ProjectEcharts :project="projectName" :data="ProjectData" :isPerson="true" :height="880"/>
        </div>
        <div style="display: flex; flex-direction: column;">
          <div class="data-box">
            <DataEcharts :project="projectName" :data="ProjectData" :isPerson="true" :height="420"/>
          </div>
          <div class="data-box">
            <SpendTimeEcharts :project="projectName" :data="daysData" :isPerson="true" :height="420"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectEcharts from '@/components/echarts/projectEcharts'
import DataEcharts from '@/components/echarts/dataEcharts'
import SpendTimeEcharts from '@/components/echarts/spendTimeEcharts'
import MemberEcharts from '@/components/echarts/memberEcharts'

export default {
  name: "Home",
  components: {
    ProjectEcharts,
    DataEcharts,
    MemberEcharts,
    SpendTimeEcharts
  },
  props: {
    PersonData: {
      type: Object
    },
    PersonDaysData: {
      type: Object
    },
  },
  data() {
    return {
      date: "",
      memberList: [],
      projectName: "",
      ProjectData: {},
      daysData: {},
      show: false,
      timer: null,
      index: 1,
    }
  },
  mounted() {
    this.getMemberOptions()
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    getMemberOptions() {
      this.memberList = []
      const nameList = ["未知用户", "Ghost User", "熊泉浪"]
      Object.keys(this.PersonData).forEach(item => {
        this.PersonData[item].ProjectName = this.PersonData[item].name
        if (!nameList.includes(item)) {
          this.memberList.push({
            name: item,
            type: 1,
          })
        }
      })
      this.projectName = this.memberList[0].name
      this.ProjectData = this.PersonData[this.projectName]
      this.daysData = this.PersonDaysData[this.projectName]
      this.setTimer()
    },
    setTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.projectName = this.memberList[this.index].name
        this.ProjectData = this.PersonData[this.projectName]
        if (this.index < this.memberList.length - 1) {
          this.index = this.index + 1
        } else {
          this.index = 0
        }
      }, 10000)
    },
    clearTimer() {
      clearInterval(this.timer)
    },
    change() {
      this.memberList.forEach(item => {
        this.$set(item, "type", item.type == 1 ? 2 : 1)
      })
    },
    selectProject(item, index) {
      this.index = index
      this.projectName = item.name
      this.ProjectData = this.PersonData[this.projectName]
      this.daysData = this.PersonDaysData[this.projectName]
      this.show = false
    },
  },
  destroyed () {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
.home {
  padding: 0 .104167rem /* 20/192 */;
  color: #fff;
  font-weight: 700;
}

.header {
  background-image: url("../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  width: 10rem;
  height: .645833rem /* 124/192 */;
  position: relative;
  top: -.052083rem /* 10/192 */;
}

.title-img {
  width: 1.541667rem /* 296/192 */;
  height: .151042rem /* 29/192 */;
  position: absolute;
  left: calc(50% - .770833rem);
  top: .15625rem /* 30/192 */;
}

.time-box {
  display: flex;
  align-items: center;
  position: absolute;
  top: .125rem /* 24/192 */;
  left: .166667rem /* 32/192 */;
}

.time-img {
  width: .125rem /* 24/192 */;
  height: .125rem /* 24/192 */;
}

.project-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 2.3125rem /* 444/192 */;
  height: .260417rem /* 50/192 */;
  background-image: url("../assets/images/project-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: -.130208rem /* 25/192 */;
  z-index: 10000;
  position: relative;
}

.select-img {
  width: .104167rem /* 20/192 */;
  height: .104167rem;
  margin-right: .104167rem;
}

.select-box {
  width: 2.208333rem /* 424/192 */;
  height: 1.197917rem /* 230/192 */;
  background-image: url("../assets/images/select-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: absolute;
  top: .833333rem /* 160/192 */;
  left: .208333rem /* 40/192 */;
  z-index: 10000;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

.select-box::-webkit-scrollbar {
  width: 0;
}

.select-item {
  width: 2.208333rem /* 424/192 */;
  height: .21875rem /* 42/192 */;
  color: #fff;
  line-height: .21875rem /* 42/192 */;
}

.select-item:hover {
  background: rgba(255, 255, 255, 0.2);
}

.project-img {
  width: .104167rem /* 20/192 */;
  height: .104167rem /* 20/192 */;
  margin-right: .052083rem /* 10/192 */;
  margin-left: .104167rem /* 20/192 */;
}

.center {
  width: 9.791667rem /* 1880/192 */;
  height: 4.848958rem /* 931/192 */;
  background-image: url("../assets/images/center-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: -.026042rem /* 5/192 */;
}

.project-box {
  width: 3.177083rem /* 610/192 */;
  height: 4.583333rem /* 880/192 */;
  background-image: url("../assets/images/data-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: .104167rem /* 20/192 */ .104167rem .104167rem;
}

.data-box {
  width: 6.302083rem /* 1210/192 */;
  height: 2.239583rem /* 430/192 */;
  background-image: url("../assets/images/month-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: .104167rem /* 20/192 */ 0 0 0;
}
</style>
