<template>
  <div class="home" @click="show = false">
    <div class="header">
      <div class="time-box">
        <img src="../assets/images/time.png" alt="" class="time-img">
        <span style=" margin-left: .03125rem ;" :style="'font-size:' + fontSize(24) +'px'">{{ date }}</span>
      </div>
      <img src="../assets/images/title.png" alt="" class="title-img">
      <div class="change" @click="tabs == 1 ? tabs = 2 : tabs = 1" :style="'font-size:' + fontSize(24) +'px'">
        切换{{ tabs == 1 ? "个人" : "项目" }}
      </div>
    </div>
    <Project v-if="tabs == 1 && flag1 && flag2" :PersonData="PersonData" :ProjectData="ProjectData"/>
    <Person v-if="tabs == 2 && flag2 && flag3" :PersonData="PersonData" :PersonDaysData="PersonDaysData" />
  </div>
</template>

<script>
import Project from '@/components/project'
import Person from '@/components/person'
import "../utils/flexible.js";
export default {
  components: {
    Project,
    Person
  },
  data() {
    return {
      date: "",
      show: false,
      tabs: 1,
      flag1: false,
      flag2: false,
      flag3: false,
      PersonData: {},
      ProjectData: {},
      PersonDaysData: {},
    }
  },
  created() {
    fetch("/static/ProjectStatistics.json").then((response) => {
      response.json().then(res => {
        this.ProjectData = res
        const date = new Date(this.ProjectData.UpdateAt);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        this.date = year + '.' + month + '.' + day;
        this.flag1 = true
      })
    })
    fetch("/static/PersonStatistics.json").then((response) => {
      response.json().then(res => {
        this.PersonData = res
        this.flag2 = true
      })
    })
    fetch("/static/PersonDaysStatistics.json").then((response) => {
      response.json().then(res => {
        this.PersonDaysData = res
        this.flag3 = true
      })
    })
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
  },
}
</script>

<style scoped>
.home {
  padding: 0 .104167rem /* 20/192 */;
  color: #fff;
  font-weight: 700;
}

.header {
  background-image: url("../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  width: 10rem /* 1920/192 */;
  height: .645833rem /* 124/192 */;
  position: relative;
  top: -.052083rem /* 10/192 */;
}

.title-img {
  width: 1.541667rem /* 296/192 */;
  height: .151042rem /* 29/192 */;
  position: absolute;
  left: calc(50% - .770833rem); /* 148/192 */
  top: .15625rem /* 30/192 */;
}

.time-box {
  display: flex;
  align-items: center;
  position: absolute;
  top: .125rem /* 24/192 */;
  left: .166667rem /* 32/192 */;
}

.time-img {
  width: .125rem /* 24/192 */;
  height: .125rem /* 24/192 */;
}

.change {
  position: absolute;
  right: .520833rem /* 100/192 */;
  top: .125rem /* 24/192 */;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
</style>