<template>
  <div @click="show = false" :style="'font-size:' + fontSize(16) +'px'">
    <div class="project-name" @click.stop="show = !show" @mouseover="clearTimer" @mouseleave="setTimer">
      <div style="display: flex; align-items: center;">
        <img src="../assets/images/project.png" alt="" class="project-img">
        <span>{{ projectName }}</span>
      </div>
      <img src="../assets/images/select.png" alt="" class="select-img">
    </div>
    <div class="select-box" v-show="show" @mouseover="clearTimer" @mouseleave="setTimer">
      <div class="select-item" v-for="(item, index) in projectList" :key="item.ID"
        @click.stop="selectProject(item, index)">
        <span style="margin-left: .15625rem;">{{ item.ProjectName }}</span>
      </div>
    </div>
    <div class="center" @mouseover="clearTimer" @mouseleave="setTimer">
      <div style="display: flex;">
        <div class="project-box">
          <ProjectEcharts :project="projectName" :data="ProjectData" />
        </div>
        <div class="data-box">
          <DataEcharts :project="projectName" :data="ProjectData" />
        </div>
      </div>
      <div class="name-list" id="nav" @mouseover="stopLoop(), clearTimer()" @mouseleave="startLoop(), setTimer()"
        @click="clicked = !clicked">
        <div class="name-item" v-for="(item, index) in memberList" :key="item.name" :id="'member' + index">
          <div class="name-box">
            <div class="name-bg">
              {{ item.name }}
            </div>
            <div class="change-bg" @click.stop="change(item)">
              <img src="../assets/images/change.png" alt="">
              切换趋势图
            </div>
          </div>
          <MemberEcharts :name="item.name" :type="item.type" :memberData="item.obj" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectEcharts from '@/components/echarts/projectEcharts'
import DataEcharts from '@/components/echarts/dataEcharts'
import MemberEcharts from '@/components/echarts/memberEcharts'

export default {
  name: "Home",
  components: {
    ProjectEcharts,
    DataEcharts,
    MemberEcharts,
  },
  props: {
    ProjectData: {
      type: Object
    },
    PersonData: {
      type: Object
    },
  },
  data() {
    return {
      date: "",
      memberList: [],
      projectName: "",
      projectList: [],
      show: false,
      marginLeft: 0,
      times: 0,
      index: 1,
      timer: null,
      timer2: null,
      clicked: false,
    }
  },
  mounted() {
    this.getMemberOptions()
    this.startLoop();
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    startLoop() {
      clearInterval(this.timer)
      if (this.clicked) return
      this.$nextTick(() => {
        if (!document.getElementById("member0")) return
        const member = document.getElementById("member0")
        member.style.transitionDuration = "1s"
        member.style.transitionDelay = "500ms"
        member.style.marginLeft = this.marginLeft
        this.timer = setInterval(() => {
          this.marginLeft = this.marginLeft - 1856
          this.times += 1
          if (this.times >= this.memberList.length / 4) {
            this.times = 0
            this.marginLeft = 0
            member.style.marginLeft = 0
          } else {
            member.style.marginLeft = this.marginLeft * .005208 + "rem"
          }
        }, 20000);
      })
    },
    stopLoop() {
      clearInterval(this.timer)
    },
    getMemberOptions() {
      this.projectList = this.ProjectData.ProjectReports
      this.projectName = this.projectList[0].ProjectName
      this.getMemberList(0)
      this.setTimer()
    },
    setTimer() {
      clearInterval(this.timer2)
      this.timer2 = setInterval(() => {
        this.projectName = this.projectList[this.index].ProjectName
        this.getMemberList(this.index)
        if (this.index < this.projectList.length - 1) {
          this.index = this.index + 1
        } else {
          this.index = 0
        }
      }, 60000)
    },
    clearTimer() {
      clearInterval(this.timer2)
    },
    change() {
      this.memberList.forEach(item => {
        this.$set(item, "type", item.type == 1 ? 2 : 1)
      })
    },
    getMemberList(index) {
      this.memberList = []
      Object.keys(this.projectList[index].SpentTimeByPerson || {}).forEach(item => {
        const nameList = ["未知用户", "Ghost User", "熊泉浪"]
        if (!nameList.includes(item)) {
          this.memberList.push({
            name: item,
            type: 1,
            obj: this.projectList[index].SpentTimeByPerson ? this.projectList[index].SpentTimeByPerson[item] : {}
          })
        }
      })
    },
    selectProject(item, index) {
      if (document.getElementById("member0")) {
        document.getElementById("member0").removeAttribute("style")
      }
      this.times = 0
      this.marginLeft = 0
      this.projectName = item.ProjectName
      this.index = index
      this.getMemberList(index)
      this.startLoop();
      this.show = false
    },
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timer2)
  },
};
</script>

<style scoped>
.home {
  padding: 0 .104167rem /* 20/192 */;
  color: #fff;
  font-weight: 700;
}

.header {
  background-image: url("../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  width: 10rem;
  height: .645833rem /* 124/192 */;
  position: relative;
  top: -.052083rem /* 10/192 */;
}

.title-img {
  width: 1.541667rem /* 296/192 */;
  height: .151042rem /* 29/192 */;
  position: absolute;
  left: calc(50% - .770833rem);
  top: .15625rem /* 30/192 */;
}

.time-box {
  display: flex;
  align-items: center;
  position: absolute;
  top: .125rem /* 24/192 */;
  left: .166667rem /* 32/192 */;
}

.time-img {
  width: .125rem /* 24/192 */;
  height: .125rem /* 24/192 */;
}

.project-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 2.3125rem /* 444/192 */;
  height: .260417rem /* 50/192 */;
  background-image: url("../assets/images/project-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: -.130208rem /* 25/192 */;
  z-index: 10000;
  position: relative;
}

.select-img {
  width: .104167rem;
  height: .104167rem;
  margin-right: .104167rem /* 20/192 */;
}

.select-box {
  width: 2.260417rem /* 434/192 */;
  height: 1.197917rem /* 230/192 */;
  background-image: url("../assets/images/select-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: absolute;
  top: .833333rem /* 160/192 */;
  left: .208333rem /* 40/192 */;
  z-index: 10000;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

.select-box::-webkit-scrollbar {
  width: 0;
}

.select-item {
  width: 2.208333rem /* 424/192 */;
  height: .21875rem /* 42/192 */;
  font-size: .083333rem /* 16/192 */;
  color: #fff;
  line-height: .21875rem /* 42/192 */;
}

.select-item:hover {
  background: rgba(255, 255, 255, 0.2);
}

.project-img {
  width: .104167rem /* 20/192 */;
  height: .104167rem /* 20/192 */;
  margin-right: .052083rem /* 10/192 */;
  margin-left: .104167rem /* 20/192 */;
}

.center {
  width: 9.791667rem /* 1880/192 */;
  height: 4.848958rem /* 931/192 */;
  background-image: url("../assets/images/center-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: -.026042rem /* 5/192 */;
}

.project-box {
  width: 3.177083rem /* 610/192 */;
  height: 3.020833rem /* 580/192 */;
  background-image: url("../assets/images/data-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: .104167rem /* 20/192 */ .104167rem 0 .104167rem;
}

.data-box {
  width: 6.302083rem /* 1210/192 */;
  height: 3.020833rem /* 580/192 */;
  background-image: url("../assets/images/month-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: .104167rem /* 20/192 */ 0 0 0;
}

.name-list {
  margin: .104167rem /* 20/192 */;
  width: 9.583333rem /* 1840/192 */;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.name-list::-webkit-scrollbar {
  height: 0;
}

.name-item {
  width: 2.3125rem /* 444/192 */;
  height: 1.484375rem /* 285/192 */;
  background-image: url("../assets/images/month-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-right: .104167rem /* 20/192 */;
}

.name-item:last-child {
  margin-right: 0;
}

.name-box {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.name-bg {
  width: .520833rem /* 100/192 */;
  height: .1875rem /* 36/192 */;
  background-image: url("../assets/images/name-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  font-size: .083333rem /* 16/192 */;
  line-height: .1875rem /* 36/192 */;
  padding-left: .104167rem /* 20/192 */;
}

.change-bg {
  width: .625rem /* 120/192 */;
  height: .1875rem /* 36/192 */;
  background-image: url("../assets/images/change-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  font-size: .083333rem /* 16/192 */;
  line-height: .135417rem /* 26/192 */;
  padding-left: .104167rem /* 20/192 */;
  font-size: .072917rem /* 14/192 */;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.change-bg img {
  width: .072917rem /* 14/192 */;
  height: .072917rem /* 14/192 */;
  margin-right: .03125rem /* 6/192 */;
}
</style>
