<template>
  <div>
    <Echart :options="options" id="memberEcharts" :height="fontSize(290) + 'px'" :width="fontSize(444) + 'px'"
      style="margin-top: -.1875rem;"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    memberData: {
      type: Object,
      default: () => { }
    },
    name: {
      type: String,
    },
    type: {
      type: Number
    },
  },
  watch: {
    type: {
      handler(newData) {
        if (newData == 2) {
          this.setData(2)
        } else {
          this.setData(1)
        }
      }
    }
  },
  mounted() {
    this.setData(1);
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    setData(type) {
      this.options = []
      if (type == 1) {
        let obj = this.memberData
        const closeData = [
          obj.SpentTime == 0 ? 0 : obj.PlantTime == 0 ? 100 :
            (obj.SpentTime / 3600).toFixed(0) / (obj.PlantTime / 3600).toFixed(0) * 100,
          obj.ClosedIncident == 0 ? 0 : obj.IncidentCount == 0 ? 100 : obj.ClosedIncident / obj.IncidentCount * 100,
          obj.ClosedIssues == 0 ? 0 : obj.IssuesCount == 0 ? 100 : obj.ClosedIssues / obj.IssuesCount * 100,
          obj.ClosedMission == 0 ? 0 : obj.MissionCount == 0 ? 100 : obj.ClosedMission / obj.MissionCount * 100,
        ]
        const rightyData = [
          (obj.SpentTime / 3600).toFixed(0) + "/" + (obj.PlantTime / 3600).toFixed(0),
          obj.ClosedIncident + "/" + obj.IncidentCount,
          obj.ClosedIssues + "/" + obj.IssuesCount,
          obj.ClosedMission + "/" + obj.MissionCount,
        ]
        // 绘制图表
        this.options = {
          color: ['#74d1fd', '#009ae4', '#0071c1'],
          // 设置图表的位置
          grid: {
            left: this.fontSize(20) + 'px',
            right: '0',
            bottom: this.fontSize(32) + 'px',
            top: this.fontSize(62) + 'px',
            containLabel: true
          },
          // X轴
          xAxis: {
            type: 'value', // 坐标轴类型,   'value' 数值轴，适用于连续数据
            min: 0,
            max: 100,
            // 坐标轴刻度
            axisTick: {
              show: false // 是否显示坐标轴刻度 默认显示
            },
            // 坐标轴轴线
            axisLine: { // 是否显示坐标轴轴线 默认显示
              show: false // 是否显示坐标轴轴线 默认显示
            },
            // 坐标轴在图表区域中的分隔线
            splitLine: {
              show: false // 是否显示分隔线。默认数值轴显示
            },
            // 坐标轴刻度标签
            axisLabel: {
              show: false // 是否显示刻度标签 默认显示
            }
          },
          yAxis: [
            // 左侧Y轴
            {
              type: 'category', // 坐标轴类型,  'category' 类目轴，适用于离散的类目数据，为该类型时必须通过 data 设置类目数据
              // 坐标轴刻度
              axisTick: {
                show: false // 是否显示坐标轴刻度 默认显示
              },
              // 坐标轴轴线
              axisLine: { // 是否显示坐标轴轴线 默认显示
                show: false, // 是否显示坐标轴轴线 默认显示
                lineStyle: { // 坐标轴线线的颜色
                  color: '#cdd3ee'
                }
              },
              // 坐标轴在图表区域中的分隔线
              splitLine: {
                show: false, // 是否显示分隔线。默认数值轴显示
              },

              // 坐标轴刻度标签
              axisLabel: {
                show: true, // 是否显示刻度标签 默认显示
                fontSize: this.fontSize(16), // 文字的字体大小
                color: '#cdd3ee', // 刻度标签文字的颜色
                // 使用字符串模板，模板变量为刻度默认标签 {value}
                formatter: '{value}'
              },
              data: ['用时', '事件', '议题', '任务'] // 类目数据，在类目轴（type: 'category'）中有效
            },
            // 右侧Y轴
            {
              type: 'category', // 坐标轴类型,  'category' 类目轴，适用于离散的类目数据，为该类型时必须通过 data 设置类目数据
              // 坐标轴轴线
              axisLine: {
                show: false
              },
              // 坐标轴刻度
              axisTick: {
                show: false
              },
              textStyle: {
                aligin: "right",
              },
              // 坐标轴刻度标签
              axisLabel: {
                show: true, // 是否显示刻度标签 默认显示
                fontSize: this.fontSize(16), // 文字的字体大小
                color: '#cdd3ee', // 刻度标签文字的颜色
                margin: this.fontSize(65), // 刻度标签与轴线之间的距离
                align: "right", // 右对齐
                // 使用字符串模板，模板变量为刻度默认标签 {value}
                formatter: function (value) {
                  let value1 = value.split("/")[0]
                  let value2 = value.split("/")[1]
                  if (value1 - value2 > 0) {
                    return '{value1|' + value1 + '}/' + value2
                  }
                  return value
                },
                rich: {
                  value1: {
                    color: "red",
                    fontSize: this.fontSize(16),
                  },
                }
              },
              data: rightyData // 类目数据，在类目轴（type: 'category'）中有效
            },
          ],
          // 系列列表
          series: [
            {
              type: 'bar', // 系列类型
              barMaxWidth: this.fontSize(10), // 柱条的最大宽度，不设时自适应
              showBackground: true, // 是否显示背景色
              // 图形上的文本标签
              label: {
                show: false,
                // 标签的位置 left right bottom top inside，绝对的像素值 position: [10, 10]，相对的百分比 position: ['50%', '50%']
                position: 'inside'
              },
              // 图形样式
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#1f1e45' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#8737f0' // 100% 处的颜色
                    }
                  ]
                },
                barBorderRadius: [0, this.fontSize(5), this.fontSize(5), 0] // 柱状图圆角
              },
              data: closeData // 系列中的数据内容数组
            },

            // 背景
            {
              type: 'bar', // 系列类型
              name: '背景', // 系列名称, 用于tooltip的显示, legend 的图例筛选
              // 不同系列的柱间距离l，如果想要两个系列的柱子重叠，可以设置 barGap 为 '-100%'。
              // 这在用柱子做背景的时候有用
              barGap: '-100%',
              barMaxWidth: this.fontSize(10), // 柱条的最大宽度，不设时自适应
              itemStyle: {
                normal: {
                  color: "rgba(28, 128, 213, 0.19)"
                }
              },
              data: [100, 100, 100, 100]
            },
            // 发光小圆点
            {
              type: 'pictorialBar', // 系列类型
              name: '圆点', // 系列名称, 用于tooltip的显示, legend 的图例筛选
              symbol: 'circle', // 标记的图形
              symbolSize: this.fontSize(14), // 标记的大小
              symbolOffset: [0, 0], // 标记的偏移
              symbolPosition: 'end', // 标记的位置
              // 图形的样式
              itemStyle: {
                color: '#fff',
                borderColor: '#1e1e44', // 阴影边框颜色
                shadowColor: '#1e1e44', // 阴影颜色
                shadowBlur: this.fontSize(10), // 图形阴影的模糊大小
                shadowOffsetX: this.fontSize(1)// 阴影水平方向上的偏移距离
              },
              z: 10, // 控制图形的前后顺序。z 值小的图形会被 z 值大的图形覆盖
              data: closeData // 系列中的数据内容数组
            }
          ]
        }
      } else {
        let obj = this.memberData.SpentTimeByMonth
        let MissionCountData = []
        let OpenedMissionData = []
        let ClosedMissionData = []
        let SpentTimeData = []
        let xData = []
        let arr = {}
        Object.keys(obj).forEach(key => {
          Object.keys(obj[key].SpentTimeByWeek).forEach(item => {
            arr[item] = obj[key].SpentTimeByWeek[item]
            xData.push(item)
          })
        })
        Object.keys(arr).map(item => {
          MissionCountData.push(arr[item].MissionCount)
          OpenedMissionData.push(arr[item].OpenedMission)
          ClosedMissionData.push(arr[item].ClosedMission)
          SpentTimeData.push((arr[item].SpentTime / 3600).toFixed(2))
        })
        this.options = {
          tooltip: {
            trigger: 'axis',
            textStyle: {
              fontSize: this.fontSize(16) // 字体大小
            },
            extraCssText: 'width:' + this.fontSize(142) + 'px;height:' + this.fontSize(132) + 'px;',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            data: ['总任务', '开启任务', '关闭任务', '实际用时'],
            top: this.fontSize(50),
            textStyle: {
              fontSize: this.fontSize(16),
            }
          },
          grid: {
            left: this.fontSize(50) + 'px',
            right: this.fontSize(20) + 'px',
            bottom: this.fontSize(20) + 'px',
            top: this.fontSize(85) + 'px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                interval: 0,
                rotate: 40,
                color: "#A8D6FF",
                textStyle: {
                  fontSize: this.fontSize(12),
                },
                margin: this.fontSize(15),
              },
              axisLine: {
                lineStyle: {
                  color: "#A8D6FF",
                },
              },
              axisPointer: {
                type: 'shadow'
              },
              data: xData,
            }
          ],
          yAxis: [
            {
              type: 'value',
              min: 0,
              axisLabel: {
                color: "#A8D6FF",
                textStyle: {
                  fontSize: this.fontSize(14),
                },
                margin: this.fontSize(15),
              },
              interval: 20,
              splitLine: {
                // 网格线
                show: true,
                lineStyle: { //分割线
                  color: "#8A9FB0",
                  width: this.fontSize(1),
                  type: "dashed" //dotted：虚线 solid:实线
                }
              },
              axisLine: {
                show: false,
              },
            }
          ],
          series: [
            {
              name: '总任务',
              type: 'line',
              smooth: false,
              symbol: 'circle',
              symbolSize: this.fontSize(8),
              itemStyle: {
                normal: {
                  color: "#FFD513",
                  lineStyle: {
                    color: "#FFD513"
                  }
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
              data: MissionCountData
            },
            {
              name: '开启任务',
              type: 'line',
              smooth: false,
              symbol: 'circle',
              symbolSize: this.fontSize(8),
              itemStyle: {
                normal: {
                  color: "#f58f23",
                  lineStyle: {
                    color: "#FF8D13"
                  }
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
              data: OpenedMissionData
            },
            {
              name: '关闭任务',
              type: 'line',
              smooth: false,
              symbol: 'circle',
              symbolSize: this.fontSize(8),
              itemStyle: {
                normal: {
                  color: "#13FFC4",
                  lineStyle: {
                    color: "#13FFC4"
                  }
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
              data: ClosedMissionData
            },
            {
              name: '实际用时',
              type: 'line',
              smooth: false,
              symbol: 'circle',
              symbolSize: this.fontSize(8),
              itemStyle: {
                normal: {
                  color: "#13CCFF",
                  lineStyle: {
                    color: "#13CCFF"
                  }
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
              data: SpentTimeData
            },
          ]
        }
      }
    },
  },
};
</script>

<style scoped></style>