<template>
  <div>
    <Echart :options="options" id="projectEcharts" :height="fontSize(height) + 'px'" :width="fontSize(610) + 'px'"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    project: {
      type: String,
    },
    data: {
      type: Object,
      default: () => { }
    },
    isPerson: {
      type: Boolean,
    },
    height: {
      type: Number,
      default: 580,
    }
  },
  watch: {
    project: {
      handler(newData) {
        this.setData();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.setData();
    }, 300);
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    setData() {
      let obj = {}
      if (this.isPerson) {
        obj = this.data
      } else {
        obj = this.data.ProjectReports.filter(item => item.ProjectName == this.project)[0]
      }
      let yData = [
        obj.IssueDelayCount,
        (obj.SpentTime / 3600).toFixed(2),
        (obj.PlantTime / 3600).toFixed(2),
        obj.NoneDueDateIssues,
        obj.RequirementCount,
        obj.ClosedTestCase,
        obj.TestCaseCount,
        obj.ClosedIncident,
        obj.IncidentCount,
        obj.ClosedIssues,
        obj.IssuesCount,
        obj.ClosedMission,
        obj.MissionCount,
      ]
      this.options = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: this.fontSize(16) // 字体大小
          },
          extraCssText: 'width:' + this.fontSize(100) + 'px;height:' + this.fontSize(60) + 'px;',
          formatter: function (params) {
            var result = ''
            var dotaDta = '<span style="display:inline-block;margin-right:5px;border-radius:16px;width:16px;height:16px;background-color: #51a7fc"></span>'
            result += params[0].axisValue + "</br>" + dotaDta + params[0].data 
            return result
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: -this.fontSize(45) + 'px',
          right: this.fontSize(40) + 'px',
          bottom: this.fontSize(18) + 'px',
          top: this.fontSize(20) + 'px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: "#A8D6FF",
            textStyle: {
              fontSize: this.fontSize(14),
            },
            margin: this.fontSize(12),
          },
          axisLine: {
            lineStyle: {
              color: "#A8D6FF",
            },
          },
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: "#A8D6FF",
            textStyle: {
              fontSize: this.fontSize(14),
              align: "left",
            },
            margin: this.fontSize(100),
          },
          axisLine: {
            show: false,
          },
          data: ["延期任务量", "实际用时(h)", "预计时间(h)", "未设置截止日", "需求", "关闭测试用例", "测试用例计数", "已关闭事件", "总事件量", "已关闭议题", "总议题量", "已关闭任务", "总任务量",]
        },
        series: [
          {
            type: 'bar',
            barWidth: this.fontSize(12) + 'px',
            itemStyle: {
              color: (params) => {
                const nameArr = ["实际用时(h)", "关闭测试用例", "已关闭事件", "已关闭议题", "已关闭任务"]
                if (nameArr.includes(params.name)) {
                  return {
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 1,
                        color: '#30F0FD' // 0% 处的颜色
                      },
                      {
                        offset: 0,
                        color: 'rgba(48,240,253,0)' // 100% 处的颜色
                      }
                    ]
                  }
                }
                return {
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 1,
                      color: '#2A8EF9' // 0% 处的颜色
                    },
                    {
                      offset: 0,
                      color: 'rgba(42,142,249,0)' // 100% 处的颜色
                    }
                  ]
                };
              },
              normal: {
                label: {
                  fontSize: this.fontSize(14)
                }
              }
            },
            label: {
              normal: {
                show: true,//开启显示
                position: 'right',//柱形上方
                textStyle: { //数值样式
                  color: '#A8D6FF '
                }
              }
            },
            data: yData
          },
        ],
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>