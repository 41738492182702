<template>
  <div>
    <Echart :options="options" id="dataEcharts" :height="fontSize(height) + 'px'" :width="fontSize(1210) + 'px'"
      style="margin-top: .104167rem /* 20/192 */;"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    project: {
      type: String,
    },
    data: {
      type: Object,
      default: () => { }
    },
    isPerson: {
      type: Boolean,
    },
    height: {
      type: Number,
      default: 555,
    }
  },
  watch: {
    project: {
      handler(newData) {
        this.setData();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.setData();
    }, 300);
  },
  methods: {
    fontSize(res) {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    setData() {
      let obj = {}
      if (this.isPerson) {
        obj = this.data
      } else {
        obj = this.data.ProjectReports.filter(item => item.ProjectName == this.project)[0]
      }
      let SpentTimeByMonth = obj.SpentTimeByMonth || {}
      let xData = []
      let arr = {}
      Object.keys(SpentTimeByMonth).forEach(key => {
        Object.keys(SpentTimeByMonth[key].SpentTimeByWeek).forEach(item => {
          arr[item] = SpentTimeByMonth[key].SpentTimeByWeek[item]
          xData.push(item)
        })
      })
      let MissionCountData = []
      let IssuesCountData = []
      let ClosedIssuesData = []
      let IncidentCountData = []
      let ClosedIncidentData = []
      let SpentTimeData = []
      Object.keys(arr).map(item => {
        MissionCountData.push(arr[item].MissionCount)
        IssuesCountData.push(arr[item].IssuesCount)
        ClosedIssuesData.push(arr[item].ClosedIssues)
        IncidentCountData.push(arr[item].IncidentCount)
        ClosedIncidentData.push(arr[item].ClosedIncident)
        SpentTimeData.push((arr[item].SpentTime / 3600).toFixed(2))
      })
      this.options = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: this.fontSize(16) // 字体大小
          },
          extraCssText: 'width:' + this.fontSize(160) + 'px;height:' + this.fontSize(195) + 'px;',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['总任务', '总议题', '关闭议题', '总事件', '关闭事件', '实际用时'],
          textStyle: {
            fontSize: this.fontSize(16),
          }
        },
        grid: {
          left: this.fontSize(50) + 'px',
          right: this.fontSize(40) + 'px',
          bottom: this.fontSize(13) + 'px',
          top: this.fontSize(50) + 'px',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              interval: 0,
              rotate: 40,
              color: "#A8D6FF",
              textStyle: {
                fontSize: this.fontSize(14),
              },
              margin: this.fontSize(12),
            },
            axisLine: {
              lineStyle: {
                color: "#A8D6FF",
              },
            },
            axisPointer: {
              type: 'shadow'
            },
            data: xData,
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            axisLabel: {
              color: "#A8D6FF",
              textStyle: {
                fontSize: this.fontSize(14),
              },
              margin: this.fontSize(15),
            },
            splitLine: {
              // 网格线
              show: true,
              lineStyle: { //分割线
                color: "#8A9FB0",
                width: 1,
                type: "dashed" //dotted：虚线 solid:实线
              }
            },
            axisLine: {
              show: false,
            },
          }
        ],
        series: [
          {
            name: '总任务',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#FFD513",
                lineStyle: {
                  color: "#FFD513"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,213,19,0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FFD513' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: MissionCountData
          },
          {
            name: '总议题',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#FF8D13",
                lineStyle: {
                  color: "#FF8D13"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,141,19, 0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FF8D13' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: IssuesCountData
          },
          {
            name: '关闭议题',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#13FFC4",
                lineStyle: {
                  color: "#13FFC4"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(19,255,196,0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#13FFC4' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: ClosedIssuesData
          },
          {
            name: '总事件',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#13CCFF",
                lineStyle: {
                  color: "#13CCFF"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(19,204,255,0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#13CCFF' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: IncidentCountData
          },
          {
            name: '关闭事件',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#1317FF",
                lineStyle: {
                  color: "#1317FF"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(19,23,255,0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#1317FF' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: ClosedIncidentData
          },
          {
            name: '实际用时',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: "#FF1395",
                lineStyle: {
                  color: "#FF1395"
                }
              },
            },
            areaStyle: {
              color: {
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,19,149,0)' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#FF1395' // 100% 处的颜色
                  }
                ]
              }
            },
            tooltip: {
              valueFormatter: function (value) {
                return value;
              }
            },
            data: SpentTimeData
          }
        ]
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>