<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    }
  },
  created() {
  }
}
</script>

<style lang="less">
body {
  width: 10rem;
  height: 5.625rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  margin: 0;
  padding: 0;
	border:0;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}


label {
  font-weight: 700;
}

html {
  height: 100%;
  box-sizing: border-box;
}

#app{
  height: 100%;
  background-color: #000001;
}
</style>
